import React, { Component } from "react";
import { Link } from "react-router-dom";

import logodarkImg from "../../assets/images/logo-dark.png";
import logosmImg from "../../assets/images/logo-sm.png";
import logolightImg from "../../assets/images/logo-light.png";

import NotificationDropdown from "../../components/NotificationDropdown";
import ProfileMenu from "../../components/ProfileMenu";
import { connect } from "react-redux";
import axios from 'axios';
import SoftPhone from "react-softphone";
import { WebSocketInterface } from "jssip";
import PhoneMenu from "../PhoneMenu";

//import forge from 'node-forge';
//import JSEncrypt from 'jsencrypt';
//import { removeQuotes } from '../../assets/js/chatMain';

const setConnectOnStartToLocalStorage = (newValue) => {
  // Handle save the auto connect value to local storage
  return true;
};
const setNotifications = (newValue) => {
  // Handle save the Show notifications of an incoming call to local storage
  return true;
};
const setCallVolume = (newValue) => {
  // Handle save the call Volume value to local storage
  return true;
};
const setRingVolume = (newValue) => {
  // Handle save the Ring Volume value to local storage
  return true;
};

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // chat: {
      //     messages: [],
      //     id: window.chat
      // },
      type: 'rsa',
      socket: new WebSocket((window.location.protocol === 'https:'?'wss://':'ws://') + window.location.hostname +`:${process.env.REACT_APP_PORT}/ws/chat/stream/`),      
      // publicKey: new JSEncrypt(),
      opened: false,
      hostname: null, 
      ws_servers: null, 
      pbx_extension: null, 
      pbx_password: null,
      call_option: 'DISABLED',
      first_name: '',
      last_name: '',
      username: '',
    };

    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.getWallboardInfo = this.getWallboardInfo.bind(this)
    // this.getPBXInfo = this.getPBXInfo.bind(this)
  }

  componentDidMount() {
    const {dbsyncdata} = this.state
    this.setupWebsocket();
    this.initUnreadCount();
    this.getWallboardInfo();
    // this.getPBXInfo();
  }

  componentWillUnmount() {
    this.state.socket.close();
  }

  getWallboardInfo = () => {
    axios.get(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/wallboard/dbsyncviewset`, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + sessionStorage.getItem("authUser").replace(/^"(.*)"$/, '$1')}})
      .then(response => {
          axios.get(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/users/me`, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + sessionStorage.getItem("authUser").replace(/^"(.*)"$/, '$1')}})
          .then(response_other => {
            this.props.setCallOption(response_other.data.results[0].call_option);
            this.props.setCallOptionChanged(true);
            this.setState({
              "hostname": response.data.results[0].hostname,
              "ws_servers": response.data.results[0].ws_servers,
              "pbx_extension": response_other.data.results[0].pbx_extension,
              "pbx_password": response_other.data.results[0].pbx_password,
              "call_option": response_other.data.results[0].call_option,
              "first_name": response_other.data.results[0].first_name,
              "last_name": response_other.data.results[0].last_name,
              "username": response_other.data.results[0].username,
            });
          })          
      })
      .catch(error => console.log(error))
  }

  // getPBXInfo = () => {
  //   axios.get(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/users/me`, {headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + sessionStorage.getItem("authUser").replace(/^"(.*)"$/, '$1')}})
  //     .then(response => {
  //       console.log('jam')
  //       console.log(response.data.results[0])
  //       this.setState({
  //         "pbx_extension": response.data.results[0].pbx_extension,
  //         "pbx_password": response.data.results[0].pbx_password
  //       })
  //     })
  //     .catch(error => console.log(error))
  // }

  initUnreadCount = () => {
    axios.get(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/unread?sender=-1&receiver=${sessionStorage.getItem("authId")}`, {'headers': this.headers})
      .then(response => {
          const unreadList = response.data.unread;
          let totalUnread = 0;
          for (const j in unreadList) {
              totalUnread += parseInt(unreadList[j].unread);
          }
          
          this.props.setUnreadCount(totalUnread);
          this.props.saveUnreadCount(unreadList);
          this.props.saveUserStatus(response.data.user_status);
      })
      .catch(error => console.log(error))
  }

  setupWebsocket() {
    let websocket = this.state.socket;
    try {
        websocket.onopen = () => {
            this.setState({opened: true});
            // let message = {
            //     command: 'prejoin',
            //     chat: this.state.chat.id,
            //     group: 'admin',
            //     user: sessionStorage.getItem("authId"),
            // };
            // this.state.opened && this.state.socket.send(JSON.stringify(message));
        };
    } catch (e) {
        console.log("== socket open error: ", e)
    }

    websocket.onmessage = (evt) => {
        let data = JSON.parse(evt.data)
        if ('key' in data) {
            // this.setState({
            //     publicKey: forge.pki.publicKeyFromPem(data.key)
            // });
        // } else if ('prejoin' in data) {
        //     console.log(" == prejoin ");
        } else if ('message' in data && data.receiver.indexOf(`_${sessionStorage.getItem("authId")}_`) > -1) {
            // let sender = data.receiver.split("_")[1];

            if (!this.state.opened) {
              this.initUnreadCount();
            }
        }
    };

    websocket.onclose = () => {
        console.log('closed')
    }
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    // this.props.toggleMenuCallback();
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  /**
   * Toggle full screen
   */
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    const {hostname, ws_servers, pbx_extension, pbx_password, first_name, last_name, username} = this.state;
    var display_name = '';
    let config = {};
    if(first_name == '' && last_name == '')
      display_name = username;
    else 
      display_name = ((first_name == '')? '' : first_name + ' ') + last_name;

    if(hostname !== null && ws_servers!==null && pbx_extension!==null && pbx_password!==null){
        config["domain"] = hostname
        config["uri"] = "sip:"+ pbx_extension +"@"+hostname
        config["password"] =  pbx_password.trim()
        config["ws_servers"] =  ws_servers
        config["extension"] = pbx_extension
        // config["sockets"] =  new WebSocketInterface(ws_servers)
        // config["display_name"] = "WebRTC Piggy Panel"
        config["display_name"] = display_name
        config["debug"] = true

        // config = {
        //   domain: hostname,
        //   uri: "sip:"+ pbx_extension +"@"+hostname,
        //   password: pbx_password,
        //   ws_servers: ws_servers,
        //   sockets: new WebSocketInterface(ws_servers),
        //   display_name: "WebRTC Piggy Panel",
        //   debug: true
        // };
        console.log(config)
        this.props.setPhoneConfig(config);
    }

    // const config = {
    //   domain: "tenerifevoip.com",
    //   uri: "sip:9998@tenerifevoip.com",
    //   password: "8bad46b1a89698717d85ecb6dbcc3be3",
    //   ws_servers: "wss://tenerifevoip.com:8089/ws",
    //   sockets: new WebSocketInterface("wss://tenerifevoip.com:8089/ws"),
    //   display_name: "WebRTC Piggy Panel",
    //   debug: true
    // };

    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div
                className="navbar-brand-box"
              >

                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logosmImg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logodarkImg} alt="" height="50" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logosmImg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logolightImg} alt="" height="50" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm mr-2 font-size-24 d-lg-none header-item waves-effect waves-light"
                onClick={this.toggleMenu}
              >
                <i className="mdi mdi-menu"></i>
              </button>
            </div>

            <div className="d-flex">
{/*}
              <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="fa fa-search"></span>
                </div>
              </form>
                  */}

{/*}
              <LanguageDropdown />
              <div className="dropdown d-none d-lg-inline-block">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                >
                  <i className="mdi mdi-fullscreen"></i>
                </button>
              </div>
    */}
              {this.props.callOption == 'SIP_BUILTIN' && Object.entries(config).length !== 0?
              // <div className="softPhoneIcon">
              //   <SoftPhone
              //     callVolume={33} //Set Default callVolume
              //     ringVolume={44} //Set Default ringVolume
              //     connectOnStart={true} //Auto connect to sip
              //     notifications={false} //Show Browser Notification of an incoming call
              //     config={config} //Voip config
              //     setConnectOnStartToLocalStorage={setConnectOnStartToLocalStorage} // Callback function
              //     setNotifications={setNotifications} // Callback function
              //     setCallVolume={setCallVolume} // Callback function
              //     setRingVolume={setRingVolume} // Callback function
              //     timelocale={"UTC+1"} //Set time local for call history
              //   />
              // </div>
              <PhoneMenu config={config} />
              : null}
              
              <NotificationDropdown  updateOpened={opened => this.setState({opened: opened})}/>
              {/* <button className="btn header-item noti-icon waves-effect" onClick={() => this.props.openChat()}>
                <i className="fa fa-comments fa-2x  pull-right msg-outline" aria-hidden="true"></i>
                <span className="badge badge-danger badge-pill" style={{ display: this.props.unread > 0 ? 'block' : 'none'}}>{this.props.unread}</span>
              </button> */}

              <ProfileMenu />
{/*}
              <div className="dropdown d-inline-block">
                <button
                  type="button"
                  onClick={this.toggleRightbar}
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="mdi mdi-settings-outline"></i>
                </button>
              </div>
*/}
              <div className="dropdown d-inline-block">
                <button
                  type="button"
                  onClick={this.toggleRightbar}
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div>
  
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => ({
  unread: state.Notification.unreadCount,
  opened: state.Notification.opened,
  callOption: state.Phone.callOption,
})

const mapDispatchtoProps = dispatch => ({
  openChat: () => {
    dispatch({
      type: "CHAT_OPEN"
    })
  },

  addUnreadCount: () => {
    dispatch({
        type: "UNREAD_ADD",
    })
  },

  setUnreadCount: (count) => {
    dispatch({
        type: "UNREAD_SET",
        payload: {
            count: count,
        }
    })
  },

  saveUnreadCount: (unreadList) => {
    dispatch({
        type: "UNREAD_SAVE",
        payload: {
            unreadList: unreadList,
        }
    })
  },

  saveUserStatus: (userStatusList) => {
    dispatch({
        type: "USER_STATUS_SAVE",
        payload: {
            userStatusList: userStatusList,
        }
    })
  },

  setPhoneConfig: (config) => {
    dispatch({
      type: "SET_PHONE_CONFIG",
      payload: {
          config: config
      }
    })
  },

  setCallOption: (option) => {
    dispatch({
      type: "SET_CALL_OPTION",
      payload: {
          callOption: option
      }
    })
  },

  setCallOptionChanged: (option) => {
    dispatch({
      type: "SET_CALL_OPTION_CHANGED",
      payload: {
          callOptionChanged: option
      }
    })
  },
})

export default connect(mapStatetoProps, mapDispatchtoProps)(TopBar);
