import React, { Component } from "react";
import { Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import { textFilter, numberFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';

import moment from 'moment';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';

const RemoteAll = ({ columns, data, page, sizePerPage, defaultSorted, onTableChange, totalSize, selectRow, rowStyle }) => (
    <PaginationProvider
        pagination={ paginationFactory({custom: true, page, sizePerPage, totalSize: totalSize, sizePerPageList: [20,50,100]}) }
      >
        {
          ({
            paginationProps,
            paginationTableProps
          }) => (
            <div>
              <BootstrapTable
                bootstrap4
                remote
                keyField="id"
                data={ data }
                columns={ columns }
                defaultSorted={ defaultSorted }
                filter={ filterFactory() }
                filterPosition="top"
                // pagination={ paginationFactory({ page, sizePerPage, totalSize }) }
                { ...paginationTableProps }
                onTableChange={ onTableChange }
                // selectRow={ selectRow }
                rowStyle={ rowStyle }
                noDataIndication={ 'No results found' }
              />
              <SizePerPageDropdownStandalone 
                { ...paginationProps }
              />
              <PaginationTotalStandalone
                { ...paginationProps }
              />
              <PaginationListStandalone
                { ...paginationProps }
              />
            </div>
          )
        }
      </PaginationProvider>
  );

class PhoneBook extends Component{
  constructor(props) {
    super(props);
    this.state = {
        isLayoutLoaded: false,
        isDataLoaded: false,
        cols: null,
        page: 1,
        sizePerPage: 20,
        totalSize: 0,
        initialFilterQuery: null,
        superuser:false,
        agent:false,
        selected:[],
        items: [],
        columns: [
          { 
            'text':'First Name',
            'dataField': 'first_name',
            'sort': true,
            // 'filter': filter,
            filter: textFilter({defaultValue: ''}),
            'headerStyle': (column, colIndex) => {
              return {
                width: '30%'
              };
            },
          },
          { 
            'text':'Last Name',
            'dataField': 'last_name',
            'sort': true,
            // 'filter': filter,
            filter: textFilter({defaultValue: ''}),
            'headerStyle': (column, colIndex) => {
              return {
                width: '30%'
              };
            },
          },
          { 
            'text':'Extension',
            'dataField': 'pbx_extension',
            'formatter': this.phoneFormatter,
            // 'sort': true,
            // 'filter': filter,
            filter: textFilter({defaultValue: ''}),
            'headerStyle': (column, colIndex) => {
              return {
                width: '30%'
              };
            },
          },
        ],
    };
    
    this.handleTableChange = this.handleTableChange.bind(this)
  } 

  async componentDidMount() {    
    if(sessionStorage.getItem("authId") !== null &&sessionStorage.getItem("access") === "agent" ){
      this.setState({
        initialFilterQuery:"",
        agent:true,
        superuser:false
      })
    }
    if(sessionStorage.getItem("authId") !== null &&sessionStorage.getItem("access") === "superuser" ){
      this.setState({
        superuser:false,
        agent:false
      })
    }
    // await this.fetchLayout();
    await this.fetchData();
    // if(this.props.location.state){
    //   if(this.props.location.state.redirectedstate !== null && this.props.location.state.redirectedstate !== undefined){
    //     let redirectedstate = this.props.location.state.redirectedstate
    //     this.setState({
    //       loading: false,
    //       items: redirectedstate.items, 
    //       page: redirectedstate.page,
    //       sizePerPage: redirectedstate.sizePerPage,
    //       nextpage: redirectedstate.nextpage,
    //       prevpage: redirectedstate.prevpage,
    //       totalSize: redirectedstate.totalSize,
    //       filters:redirectedstate.filters,
    //       isDataLoaded:true, 
    //     })
        
    //   } else {
    //     await this.fetchData();
    //   }
    // } else {
    //   await this.fetchData();
    // }
    
  }

  fetchData() {
    const { loaded, sizePerPage, page, initialFilterQuery, sortField, sortOrder, filters, searchText  } = this.state;
    let qs =  {}

    // console.log('FetchData');
    // console.log(this.state.filters)
    
    qs = `/?&page=`+page+`&page_size=`+sizePerPage;

    if(initialFilterQuery){
      qs = qs + initialFilterQuery
    }

    for (let dataField in filters) {
      const { filterVal, filterType, comparator } = filters[dataField];
      dataField = dataField.replace(/\./g,'__')
      if(filterType==="TEXT"){            
        qs += '&'+dataField+'__icontains='+filterVal
      }
      if(filterType==="NUMBER"){
        if(filterVal.comparator===">"){
          qs += '&'+dataField+'__gt='+filterVal.number
        } else if(filterVal.comparator===">="){
          qs += '&'+dataField+'__gte='+filterVal.number
        } else if(filterVal.comparator==="<"){
          qs += '&'+dataField+'__lt='+filterVal.number
        } else if(filterVal.comparator==="<="){
          qs += '&'+dataField+'__lte='+filterVal.number
        }  else if(filterVal.comparator==="!="){
          qs += '&'+dataField+'!='+filterVal.number
        }  else if(filterVal.comparator==="="){
          qs += '&'+dataField+'='+filterVal.number
        }
      }
      if(filterType==="SELECT"){
        if(comparator === "="){
          qs += '&'+dataField+'='+filterVal
        }
      }
      if(filterType==="DATE"){
        if(moment(filterVal.date).isValid()){
          let dateVal = moment(filterVal.date).format('YYYY-MM-DD')
          if(filterVal.comparator===">"){
            qs += '&'+dataField+'__gt='+dateVal
          } else if(filterVal.comparator===">="){
            qs += '&'+dataField+'__gte='+dateVal
          } else if(filterVal.comparator==="<"){
            qs += '&'+dataField+'__lt='+dateVal
          } else if(filterVal.comparator==="<="){
            qs += '&'+dataField+'__lte='+dateVal
          }  else if(filterVal.comparator==="!="){
            qs += '&'+dataField+'!='+dateVal
          }  else if(filterVal.comparator==="="){
            qs += '&'+dataField+'__startswith='+dateVal
          }
        }
      }
    }

    if(typeof sortField !=='undefined' && typeof sortOrder!=='undefined'){
      let sortFieldVar = sortField.replace(/\./g,'__')
      //console.log("Replaced:"+sortField)
      if(sortOrder ==='asc'){
        qs += '&ordering='+sortFieldVar
      } else if(sortOrder ==='desc'){
        qs += '&ordering=-'+sortFieldVar
      }
    }

    if(typeof searchText !== 'undefined'){
      qs += '&search='+searchText
    }
    console.log(qs);

    fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/users/phonebook${qs}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization':'Token '+sessionStorage.getItem("authUser").replace(/^"(.*)"$/, '$1')}
    })
    .then(res => res.json())
    .then(data => {
        this.setState({
          loading: false,
          items: data.results, 
          totalSize: data.total, 
          page:data.page, 
          nextpage: data.links.next,
          prevpage: data.links.previous,
          sizePerPage:data.page_size,
          isDataLoaded:true,            
        });
    })
    .catch(error => {
        console.log(error);
    });
  }

  handleTableChange(type, { page, sizePerPage, sortField, sortOrder, filters, searchText }) {
    // console.log('Filters')
    // console.log(filters)     
    this.setState({ 
      page: page,
      sizePerPage: sizePerPage,
      loading: true,
      sortField:sortField,
      sortOrder:sortOrder,
      filters:filters,
      loaded:true
    }, () => this.fetchData());
  }

  phoneFormatter = (cell,row,rowIndex,formatExtraData)=>{
    let phonelink;
    if(cell != null){
      switch(this.props.callOption){
        case 'DISABLED':
          phonelink = cell;
          break;
        case 'SIP_BUILTIN':
          var fullname = row.first_name + ' ' + row.last_name;
          phonelink = <a href="#" onClick={() => { if(this.props.phoneStatus == "SERVER_CONNECTED"){ this.props.setPhoneNumber(cell, fullname); this.props.setDisplayName(fullname); this.props.setPhoneClicked(true); }}}>{cell}</a>;
          break;
        case 'SIP_EXTERNAL':
          let cell_sip = "sip:"+cell;
          phonelink = <a href={cell_sip}>{cell}</a>;
          break;
        default:
          phonelink = cell;
          break;
      }
      // let cell_sip = "sip:"+cell;
      // return(<a href="#" onClick={() => { if(this.props.phoneStatus == "SERVER_CONNECTED"){ this.props.setPhoneNumber(cell); this.props.setPhoneClicked(true); }}}>{cell}</a>)
    }
    return(phonelink);
  }

  
  render() {  
    const { isLayoutLoaded, isDataLoaded, page, sizePerPage, selected, showDelAlert, totaldeleted, superuser } = this.state;
    // console.log(this.state)
    // var callOption = this.props.callOption;
    // console.log(callOption);
    var columns = [];
    let selectRow;
    // if(superuser){
    //   selectRow = {
    //     mode: 'checkbox',
    //     clickToSelect: true,
    //     onSelect: this.handleOnSelect,
    //     onSelectAll: this.handleOnSelectAll
    //   };
    // } else {
    //   selectRow = undefined;
    // }

    // if((isLayoutLoaded)){
    //   let total_col_units =3 ;
    //   // for (let prop in cols) {
    //   //   total_col_units += parseInt(this.state.cols[prop].layout_col_width);
    //   // }
    //   let per_unit_width = 80/total_col_units;
    //   for (let prop in cols) {
    //     let col = this.state.cols[prop];
    //     let filter = null;
    //     let col_width = col.layout_col_width * per_unit_width;
    //     if(col.layout_field_searchable){
    //       if(col.layout_field.field_type === "text" || col.layout_field.field_type === "email" || col.layout_field.field_type === "phone"){
    //         filter = textFilter();
    //         if(this.state.filters){
    //           let field_name = 'data.'+col.layout_field.field_api_name
    //           if(this.state.filters[field_name]){
    //             filter = textFilter({defaultValue: this.state.filters[field_name]['filterVal']});
    //           } b
    //         }
    //       }
    //       if(col.layout_field.field_type === "datetime" || col.layout_field.field_type === "date"){
    //         filter = dateFilter();
    //         if(this.state.filters){
    //           let field_name = 'data.'+col.layout_field.field_api_name
    //           if(this.state.filters[field_name]){
    //             filter = dateFilter({defaultValue: this.state.filters[field_name]['filterVal']});
    //           }
    //         }
    //       }
    //       if(col.layout_field.field_type === "integer" || col.layout_field.field_type === "bigint"){
    //         filter = numberFilter();
    //         if(this.state.filters){
    //           let field_name = 'data.'+col.layout_field.field_api_name
    //           if(this.state.filters[field_name]){
    //             filter = numberFilter({defaultValue: this.state.filters[field_name]['filterVal']});
    //           }
    //         }
    //       }
    //       if(col.layout_field.field_type === "picklist"){
    //         let selectOptions_tmp = Object.fromEntries(Object.entries(col.layout_field.field_data).sort(function (a, b) {
    //           return a.toString().toLowerCase().localeCompare(b.toString().toLowerCase());
    //         }))
    //         let selectOptions = Object.assign({}, ...Object.entries(selectOptions_tmp).map(([a,b]) => ({ [a]: a })))
    //         // delete selectOptions['-None-'];
    //         filter = selectFilter({
    //           options: selectOptions
    //         })
    //         if(this.state.filters){
    //           let field_name = 'data.'+col.layout_field.field_api_name
    //           if(this.state.filters[field_name]){
    //             filter = selectFilter({
    //               options: selectOptions,
    //               defaultValue: this.state.filters[field_name]['filterVal']
    //             })
    //           }
    //         }
    //       }
    //       if(col.layout_field.field_type === "lookup"){
    //         let selectOptions_tmp = Object.fromEntries(Object.entries(col.layout_field.field_data).sort(function (a, b) {
    //           return a.toString().toLowerCase().localeCompare(b.toString().toLowerCase());
    //         }))
    //         let selectOptions = Object.assign({}, ...Object.entries(selectOptions_tmp).map(([a,b]) => ({ [b]: a })))
    //         filter = selectFilter({
    //           options: selectOptions
    //         })
    //         if(this.state.filters){
    //           let field_name = 'data.'+col.layout_field.field_api_name
    //           if(this.state.filters[field_name]){
    //             filter = selectFilter({
    //               options: selectOptions,
    //               defaultValue: this.state.filters[field_name]['filterVal']
    //             })
    //           }
    //         }
    //       }
    //       if(col.layout_field.field_type === "boolean"){
    //         const selectOptions = {
    //           true: 'True',
    //           false: 'False'
    //         };
    //         // formatter = cell => selectOptions[cell],
    //         filter = selectFilter({
    //           options: selectOptions
    //         })
    //       }
    //     }

    //     if( col.layout_field.field_type === "phone" ){
    //       columns.push({ 'text':col.layout_field.field_label,
    //                   'dataField': 'data.'+col.layout_field.field_api_name,
    //                   'sort': Boolean(col.layout_field_sortable),
    //                   'filter': filter,
    //                   'formatter': this.phoneFormatter,
    //                   'headerStyle': (column, colIndex) => {
    //                     return {
    //                       width: col_width+'%'
    //                     };
    //                   },
    //                 });
    //     } else if(col.layout_field.field_type === "date" || col.layout_field.field_type === "datetime"){
    //       columns.push({ 'text':col.layout_field.field_label,
    //                   'dataField': 'data.'+col.layout_field.field_api_name,
    //                   'sort': Boolean(col.layout_field_sortable),
    //                   'filter': filter,
    //                   'formatter': this.dateFormatter,
    //                   'formatExtraData':{
    //                     'type': col.layout_field.field_type
    //                   },
    //                   'headerStyle': (column, colIndex) => {
    //                     return {
    //                       width: col_width+'%'
    //                     };
    //                   },
    //                 });
    //     } else {
    //       columns.push({ 'text':col.layout_field.field_label,
    //                   'dataField': 'data.'+col.layout_field.field_api_name,
    //                   'sort': Boolean(col.layout_field_sortable),
    //                   'filter': filter,
    //                   'headerStyle': (column, colIndex) => {
    //                     return {
    //                       width:  col_width+'%'
    //                     };
    //                   },
    //                 });
    //     }
    //   }
    //   columns.push({'text':'ID', 'dataField': 'id','hidden':true})
    //   columns.push({
    //                 'text':'Actions', 
    //                 'dataField':'actions',
    //                 'sort': false, 
    //                 'formatter': this.actionFormatter,
    //                 'headerStyle': (column, colIndex) => {
    //                   return {
    //                     width: '5%'
    //                   };
    //                 },
    //                 'formatExtraData':{
    //                                   'link_to':'add-edit-lead',
    //                                   'query_vars':[
    //                                     {'param_name':'lead_id','field_name':'id'}
    //                                   ],
    //                                   'state_vars':
    //                                     {'items':this.state.items, 'page': this.state.page, 'sizePerPage': this.state.sizePerPage, 'totalSize': this.state.totalSize, 'nextpage': this.state.nextpage, 'prevpage':this.state.prevpage, filters:this.state.filters}
    //                                   ,
    //                                   'edit':true,
    //                                   'editstr':'Edit',
    //                                   'delete':true,
    //                                   }
    //                 })
    //   // console.log('Cols')
    //   // console.log(columns)
    // }

    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (rowIndex % 2 === 0) {
        style.backgroundColor = '#f8f9fa';
      }    
      return style;
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <RemoteAll
                    columns = { this.state.columns }
                    data={ this.state.items }
                    page={ page }
                    sizePerPage={ sizePerPage }
                    totalSize={ this.state.totalSize }
                    onTableChange={ this.handleTableChange }
                    // selectRow={ selectRow }
                    rowStyle={ rowStyle }
                  /> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => ({
  phoneStatus: state.Phone.phoneStatus,
  callOption: state.Phone.callOption,
  callOptionChanged: state.Phone.callOptionChanged,
})

const mapDispatchtoProps = dispatch => ({
  setPhoneNumber: (number, name) => {
    dispatch({
      type: "SET_PHONE_NUMBER",
      payload: {
          phoneNumber: number,
          displayName: name
      }
    })
  },
  setDisplayName: (name) => {
    dispatch({
      type: "SET_DISPLAY_NAME",
      payload: {
        setDisplayName: name
      }
    })
  },
  setPhoneClicked: (phoneClicked) => {
    dispatch({
      type: "IS_PHONE_NUMBER_CLICKED",
      payload: {
        isPhoneNumberClicked: phoneClicked
      }
    })
  },
})
  
export default connect(mapStatetoProps, mapDispatchtoProps)(PhoneBook);