import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { WebSocketInterface, UA } from "jssip";
import { connect } from "react-redux";
import incomingRingtone from "../assets/audio/ringtone.mp3";
import moment from 'moment';

class PhoneHistoryItem extends Component {
  constructor(props) {
    super(props);
    
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5, marginBottom: 5, paddingLeft: 10}}>
          {
            this.props.ext == this.props.data.out_ext?
            (
              <i className="mdi mdi-phone-outgoing" style={{fontSize: 20, color: 'green'}}></i>
            )
            : (
              this.props.data.disposition == 'NOANSWER' ?
              (
                <i className="mdi mdi-phone-missed" style={{fontSize: 20, color: 'red'}}></i>
              )
              : (
                <i className="mdi mdi-phone-incoming" style={{fontSize: 20, color: 'green'}}></i>
              )
            )
          }
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10}}>
            <div style={{ fontWeight: 'bold', paddingBottom: '0px !important' }}>
              
                {
                  this.props.ext == this.props.data.out_ext ? 
                  (
                    <a href="#" 
                      onClick={() => 
                        { 
                          if(this.props.phoneStatus == "SERVER_CONNECTED"){ 
                            this.props.setPhoneNumber(this.props.data.in_number, this.props.data.in_display_name); 
                            this.props.setPhoneClicked(true); 
                          }
                        }}>
                          {
                            this.props.data.in_display_name != '' && this.props.data.in_display_name != this.props.data.in_number ? 
                              this.props.data.in_display_name  + ' (' + this.props.data.in_number + ')'
                            : this.props.data.in_number
                          }
                    </a>
                  ) 
                  : (
                    <a href="#" 
                      onClick={() => 
                        { 
                          if(this.props.phoneStatus == "SERVER_CONNECTED"){ 
                            this.props.setPhoneNumber(this.props.data.out_ext, this.props.data.out_display_name); 
                            this.props.setPhoneClicked(true); 
                          }
                        }}>
                          {
                            this.props.data.out_display_name != ''  && this.props.data.out_display_name != this.props.data.out_ext ? 
                            this.props.data.out_display_name + ' (' + this.props.data.out_ext + ')'
                            : this.props.data.out_ext
                          }
                    </a>
                    )
                }
                
            </div>
            <div style={{ paddingTop: '0px !important', fontSize: 12 }}>{moment(this.props.data.call_date).calendar()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStatetoProps = state => ({
  phoneNumber: state.Phone.phoneNumber,
  displayName: state.Phone.displayName,
  phoneStatus: state.Phone.phoneStatus,
  isPhoneNumberClicked: state.Phone.isPhoneNumberClicked
})

const mapDispatchtoProps = dispatch => ({
  setPhoneNumber: (number, name) => {
    dispatch({
      type: "SET_PHONE_NUMBER",
      payload: {
          phoneNumber: number,
          displayName: name
      }
    })
  },
  setPhoneClicked: (phoneClicked) => {
    dispatch({
      type: "IS_PHONE_NUMBER_CLICKED",
      payload: {
        isPhoneNumberClicked: phoneClicked
      }
    })
  },
})

export default connect(mapStatetoProps, mapDispatchtoProps)(PhoneHistoryItem);

