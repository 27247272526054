import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { WebSocketInterface, UA } from "jssip";
import { connect } from "react-redux";
import incomingRingtone from "../assets/audio/ringtone.mp3";
import PhoneHistoryItem from './PhoneHistoryItem';
import CallOperations from '../helpers/call';
import PhoneBook from './PhoneBook';
import { relativeTimeThreshold } from 'moment';

class PhoneMenu extends Component {
  _phoneStatus = {
    BUSY: 'BUSY',   //when call is either initiated or received and its ON
    INCOMING_CALL_RINGING: 'INCOMING_CALL_RINGING',   //when getting incoming call ring
    CALL_ANSWERED: 'CALL_ANSWERED',   //when incoming call is answered; may not be used or used for a fraction of second
    CALL_INITIATED: 'CALL_INITIATED',   //when outgoing call is initiated; may not be used or used for a fraction of second
    SERVER_CONNECTED: 'SERVER_CONNECTED',   //when server is connected
    SERVER_CONNECTING: 'SERVER_CONNECTING',   //when server is in connection mode
    SERVER_DISCONNECTED: 'SERVER_DISCONNECTED'   //when server connections is failed
  }

  _token = sessionStorage.getItem("authUser").replace(/^"(.*)"$/, '$1');
  phone = null;
  callEventHandlers = null;
  callOptions = null;
  _callOperations = new CallOperations();

  state = {
    menu: false,
    hideHistoryDropdown: true,
    hidePhoneBookDropdown: true,
    // phoneStatus: this._phoneStatus.SERVER_DISCONNECTED,
    // isPhonePanelVisible: false,
    // config: this.props.config,
    // isSocketConnected: false,
    // isSocketRegistered: false,
    // callSession : null,
    // callOriginator : ''
  };

  constructor(props) {
    super(props);
    
    // this.toggleHistory = this.toggleHistory.bind(this);
    this.showPhonePanel = this.showPhonePanel.bind(this);
    this.answerCall = this.answerCall.bind(this);
    this.disconnectCall = this.disconnectCall.bind(this);
    this.dialCall = this.dialCall.bind(this);
    this.newCallSession = this.newCallSession.bind(this);
    this.callInProgress = this.callInProgress.bind(this);
    this.callFailed = this.callFailed.bind(this);
    this.callMissed = this.callMissed.bind(this);
    this.callEnded = this.callEnded.bind(this);
    this.callConfirmed = this.callConfirmed.bind(this);
    this.callAccepted = this.callAccepted.bind(this);
    this.callRejected = this.callRejected.bind(this);
    this.muteCall = this.muteCall.bind(this);
    this.holdCall = this.holdCall.bind(this);
    this.serverConnected = this.serverConnected.bind(this);
    this.serverDisconnected = this.serverDisconnected.bind(this);
    this.serverRegistered = this.serverRegistered.bind(this);
    this.serverUnregistered = this.serverUnregistered.bind(this);
    this.serverRegistrationFailed = this.serverRegistrationFailed.bind(this);
    this.incomingConnectionEvent = this.incomingConnectionEvent.bind(this);
    this.incomingConnectionDataStream = this.incomingConnectionDataStream.bind(this);
    // this.newInfo = this.newInfo.bind(this);

    console.log('user details:');
    console.log(this.props.config);
    if(this.props.config.ws_servers && 
        this.props.config.ws_servers != '' && 
        this.props.config.extension != '' && 
        (this.props.phone == null || !this.props.phone.isConnected())) {

      var socket = new WebSocketInterface(this.props.config.ws_servers);
      var configuration = {
        sockets  : [ socket ],
        uri      : this.props.config.uri,
        password : this.props.config.password,
        register : true,
      };

      var phone = new UA(configuration);
      phone.on('connected', this.serverConnected);

      phone.on('disconnected', this.serverDisconnected);
      phone.on('newRTCSession', this.newCallSession);
      phone.on('registered', this.serverRegistered);
      phone.on('unregistered', this.serverUnregistered);
      phone.on('registrationFailed', this.serverRegistrationFailed);

      phone.start();
      phone.on('newMessage', this.newOptions);
      this.props.setPhone(phone);
    }

    // Register callbacks to desired call events
    this.callEventHandlers = {
      'progress': this.callInProgress,
      'failed': this.callFailed,
      'ended': this.callEnded,
      'confirmed': this.callConfirmed,
      'accepted': this.callAccepted,
      'cancel': this.callRejected,
    };

    this.callOptions = {
      'eventHandlers'    : this.callEventHandlers,
      'mediaConstraints' : { 'audio': true, 'video': false },
      'extraHeaders': [ 'X-Foo: foo', 'X-Bar: bar' ],
    };

    // this.props.setPhoneStatus(this._phoneStatus.SERVER_CONNECTED);
    this.getCallHistory();
  }

  newOptions(e){
    console.log(e);
  }

  getCallHistory() {
    this._callOperations.getCalls()
    .then(data => {
      this.props.setCallHistory(data);
    })
    .catch(error => {
      console.log(error);
    })
  }

  serverConnected(e){ 
    console.log('server connected'); 
    console.log(e); 
    this.props.setPhoneStatus(this._phoneStatus.SERVER_CONNECTED);
    // this.setState({isSocketConnected: true, phoneStatus:this._phoneStatus.SERVER_CONNECTED}); 
  }

  serverDisconnected(e) {
    console.log('server disconnected');
    console.log(e);
    this.props.setPhoneStatus(this._phoneStatus.SERVER_DISCONNECTED);
    // this.setState({isSocketConnected: false, phoneStatus:this._phoneStatus.SERVER_DISCONNECTED});
  }

  serverRegistered(e){  
    console.log('server registered'); 
    console.log(e); 
    this.setState({isSocketRegistered: true}); 
  }

  serverUnregistered(e){  
    console.log('sesrver unregistered'); 
    console.log(e); 
    this.props.setPhoneStatus(this._phoneStatus.SERVER_DISCONNECTED);
    // this.setState({isSocketRegistered: false, phoneStatus:this._phoneStatus.SERVER_DISCONNECTED}); 
  }

  serverRegistrationFailed(e){ 
    console.log('server registrationFailed'); 
    console.log(this); 
    this.props.setPhoneStatus(this._phoneStatus.SERVER_DISCONNECTED);
    // this.setState({isSocketRegistered: false, phoneStatus:this._phoneStatus.SERVER_DISCONNECTED});
  }

  // toggleHistory() {
  //   this.setState({hideHistoryDropdown: !this.state.hideHistoryDropdown});
  // }

  showPhonePanel() {
    var isPhonePanelVisible = !this.props.isPhonePanelVisible;
    
    if(!isPhonePanelVisible) this.props.setPhoneNumber('', '');

    this.props.setPhonePanelVisible(isPhonePanelVisible);
    this.setState({ hideHistoryDropdown: true });
  }

  newCallSession(e) {
    console.log('newcallsession');
    console.log(e);
    if(e.originator == 'local') {
      this.props.setPhonePanelVisible(true);
      this.props.setCallOriginator('local');
      this.props.setCallSession(e.session);

      // var entry = {
      //   direction: 'OUTGOING',
      //   missed: false,
      //   remoteuser: this.props.phoneNumber,
      //   starttime: Date().toLocaleString()
      // }
      // var history = this.props.callHistory;
      // history.unshift(entry);
      // this.props.setCallHistory(history);

      //// Disable ringing mp3 on outbound
      try {
        const ele = document.getElementById('audIncomingCall');
        ele.muted = false;
        ele.play();
        ele.volume = 0.15;
      }
      catch(e) { console.log(e); }

      // this.setState({ callSession: e.session, callOriginator: 'local', sPhonePanelVisible: true, });
      if(e.session.connection) {
        console.log(e.session);
        // console.log(e.session.connection);

        e.session.connection.onaddstream=function (es) {
          document.getElementById('audIncomingCall').pause();
          document.getElementById('audIncomingCall').currentTime = 0;
          // set remote audio stream
          const remoteAudio = document.getElementById('audCall');
          remoteAudio.srcObject = es.stream;
          remoteAudio.play();
        }
        
      }
    }
    else if(e.originator == 'remote') {      
      try {
        const ele = document.getElementById('audIncomingCall');
        ele.muted = false;
        ele.play();
        ele.volume = 0.5;
      }
      catch(e) { console.log(e); }
      this.props.setPhonePanelVisible(true);
      this.props.setCallOriginator('remote');
      this.props.setCallSession(e.session);
      this.props.setPhoneStatus(this._phoneStatus.INCOMING_CALL_RINGING);
      // this.setState({ callSession: e.session, callOriginator: 'remote', sPhonePanelVisible: true, phoneStatus: this._phoneStatus.INCOMING_CALL_RINGING });
      // TODO: get the call id from SIP request and set state
      if(e.session) {
        this.props.setPhoneNumber(e.session.remote_identity.uri.user, e.session.remote_identity.uri.user);
        // var entry = {
        //   direction: 'INCOMING',
        //   missed: true,
        //   remoteuser: e.session.remote_identity.uri.user,
        //   starttime: Date().toLocaleString()
        // }
        // var history = this.props.callHistory;
        // history.unshift(entry);
        // this.props.setCallHistory(history);
        var date = Date.now()/1000;
        try {
          this._callOperations
          .createCall(this._token, date, e.session.remote_identity.uri.user, '', e.session.remote_identity.uri.user, this.props.config.extension, this.props.config.display_name)
          .then(callId => {
            console.log('callid');
            console.log(callId);
            this.props.setCallRecordId(callId);
            var callHistory = this.props.callHistory;
            var item = {
              id: 0,
              call_date: date,
              out_ext: e.session.remote_identity.uri.user,
              out_sip_id: "",
              out_display_name: e.session.remote_identity.uri.user,
              in_number: this.props.config.extension, 
              in_display_name: this.props.config.display_name,
              duration: 0,
              billable: 0,
              disposition: ""
            }
            callHistory.unshift(item);
            this.props.setCallHistory(callHistory);
          })
          .catch(error => console.log(error))
        }
        catch(e) { console.log(e); }
  
        e.session.on('failed', this.callMissed);
        e.session.on('peerconnection', this.incomingConnectionEvent);
      }
    }
  }

  incomingConnectionEvent(e) {
    e.peerconnection.addEventListener('addstream', this.incomingConnectionDataStream);
  }

  incomingConnectionDataStream(e) {
    // var history = this.props.callHistory;
    // history[0].missed = false;
    // this.props.setCallHistory(history);
    // set remote audio stream
    try{
      const remoteAudio = document.getElementById('audCall');
      remoteAudio.srcObject = e.stream;
      remoteAudio.play();
    } catch(e) {
      console.log(e);
    }
  }
  
  callInProgress(e) {
    console.log('CallInPogress');
    console.log(e);
    this.props.setPhonePanelVisible(true);

    // this.props.callSession.sendInfo('application/json', '{\'id\': ' + this.props.callRecordId + '}');

    // this.props.setPhoneStatus(this._phoneStatus.BUSY);
    // this.setState({isPhonePanelVisible: true, phoneStatus: this._phoneStatus.BUSY});
  }

  callFailed(e) {
    console.log('callFailed');
    console.log(e);

    try {
      if(this.props.callRecordId != null)
        this._callOperations
          .endCall(this._token, this.props.callRecordId, Date.now()/1000);

      var callHistory = this.props.callHistory;
      var historyItem = callHistory.find((val, index) => val.id == this.props.callRecordId);
      if (historyItem) {
        historyItem.disposition = "NOANSWER";
        this.props.setCallHistory(callHistory);
      }
    }
    catch(e) { console.log(e); }
    
    this.props.setCallRecordId(null);
    this.props.setPhonePanelVisible(false);
    this.props.setPhoneStatus(this._phoneStatus.SERVER_CONNECTED);
    this.props.setCallSession(null);

    this.props.setIsAttendedCallTransferClicked(false);
    this.props.setIsAttendedCallTransferDialed(false);
    this.props.setIsUnattendedCallTransferClicked(false);
    this.props.setIsUnattendedCallTransferDialed(false);
    this.props.setTransferPhoneNumber(null, null);

    document.getElementById('audIncomingCall').pause();
    document.getElementById('audIncomingCall').currentTime = 0;
    // this.setState({isPhonePanelVisible: false, phoneStatus: this._phoneStatus.SERVER_CONNECTED});
  }
  
  callMissed(e) {
    console.log('callMissed');
    console.log(e);

    try {
      this._callOperations
        .updateCallStatusFromRemote(this._token, this.props.phoneNumber, this.props.config.extension, e.cause == 'Rejected'? 'REJECTED' : 'NOANSWER');
    }
    catch(er) { console.log(er); }


    var callHistory = this.props.callHistory;
    var historyItem = callHistory.find((val, index) => val.id == this.props.callRecordId);
    if (historyItem) {
      historyItem.disposition = e.cause == 'Rejected'? 'REJECTED' : 'NOANSWER';
      this.props.setCallHistory(callHistory);
    }

    // var history = this.props.callHistory;
    // history[0].missed = e.cause == 'Canceled' ? true : false;
    // this.props.setCallHistory(history);
    this.props.setCallMissed(( e.originator = 'remote' && e.cause == 'Canceled') || (e.originator= 'local' && e.cause == 'No Answer'));
    this.props.setPhonePanelVisible(false);
    this.props.setPhoneStatus(this._phoneStatus.SERVER_CONNECTED);
    this.props.setCallSession(null);

    this.props.setIsAttendedCallTransferClicked(false);
    this.props.setIsAttendedCallTransferDialed(false);
    this.props.setIsUnattendedCallTransferClicked(false);
    this.props.setIsUnattendedCallTransferDialed(false);
    this.props.setTransferPhoneNumber(null, null);

    document.getElementById('audIncomingCall').pause();
    document.getElementById('audIncomingCall').currentTime = 0;
    // this.setState({isPhonePanelVisible: false, phoneStatus: this._phoneStatus.SERVER_CONNECTED});
  }

  callEnded(e) {
    console.log('callEnded');
    console.log(e);
    this.props.setPhonePanelVisible(false);
    this.props.setPhoneStatus(this._phoneStatus.SERVER_CONNECTED);
    this.props.setCallSession(null);

    try {
      if(this.props.callRecordId != null)
        this._callOperations
          .endCall(this._token, this.props.callRecordId, Date.now()/1000);
    }
    catch(e) { console.log(e); }

    this.props.setCallRecordId(null);

    this.props.setIsAttendedCallTransferClicked(false);
    this.props.setIsAttendedCallTransferDialed(false);
    this.props.setIsUnattendedCallTransferClicked(false);
    this.props.setIsUnattendedCallTransferDialed(false);
    this.props.setTransferPhoneNumber(null, null);
    // this.setState({isPhonePanelVisible: false, callSession: null, phoneStatus: this._phoneStatus.SERVER_CONNECTED});
  }

  callConfirmed(e) {
    console.log('callConfirmed');
    console.log(e);
    this.props.setPhonePanelVisible(true);
    // this.props.setPhoneStatus(this._phoneStatus.BUSY);
    // this.setState({isPhonePanelVisible: true, phoneStatus: this._phoneStatus.BUSY});
  }

  callAccepted(e) {
    console.log('callAccepted');
    console.log(e);
    this.props.setPhonePanelVisible(true);
    this.props.setPhoneStatus(this._phoneStatus.BUSY);
    
    try {
      if(this.props.callRecordId != null)
        this._callOperations
          .answerCall(this._token, this.props.callRecordId, Date.now()/1000);
    }
    catch(e) { console.log(e); }
    // this.setState({isPhonePanelVisible: true, phoneStatus: this._phoneStatus.BUSY});
  }

  callRejected(e) {
    console.log('callRejected');
    console.log(e);
    this.props.setPhonePanelVisible(false);
    this.props.setPhoneStatus(this._phoneStatus.SERVER_CONNECTED);

    this.props.setIsAttendedCallTransferClicked(false);
    this.props.setIsAttendedCallTransferDialed(false);
    this.props.setIsUnattendedCallTransferClicked(false);
    this.props.setIsUnattendedCallTransferDialed(false);
    this.props.setTransferPhoneNumber(null, null);
    // this.setState({isPhonePanelVisible: true, phoneStatus: this._phoneStatus.BUSY});
  }

  answerCall() {
    console.log('answerCall');
    try{
      if(this.props.callSession) {
        this.props.callSession.answer();
        document.getElementById('audIncomingCall').pause();
        document.getElementById('audIncomingCall').currentTime = 0;
        this.props.setPhonePanelVisible(true);
        this.props.setPhoneStatus(this._phoneStatus.BUSY);

        var callHistory = this.props.callHistory;
        var historyItem = callHistory.find((val, index) => val.id == this.props.callRecordId);
        if (historyItem) {
          historyItem.disposition = 'ANSWERED';
          this.props.setCallHistory(callHistory);
        }
      }
    }
    catch(e){
      console.log(e);
    }
    // this.setState({isPhonePanelVisible: true, phoneStatus: this._phoneStatus.BUSY});
  }

  disconnectCall() {
    console.log('disconnectCall');
    try{
      if(this.props.callSession)
        this.props.callSession.terminate();
    }
    catch(e){
      console.log(e);
    }

    document.getElementById('audIncomingCall').pause();
    document.getElementById('audIncomingCall').currentTime = 0;

    document.getElementById('audCall').srcObject = null;

    this.props.setPhonePanelVisible(false);
    this.props.setPhoneStatus(this._phoneStatus.SERVER_CONNECTED);
    this.props.setIsAttendedCallTransferClicked(false);
    this.props.setIsAttendedCallTransferDialed(false);
    this.props.setIsUnattendedCallTransferClicked(false);
    this.props.setIsUnattendedCallTransferDialed(false);
    this.props.setTransferPhoneNumber(null, null);
  }

  dialCall() {
    console.log('dialCall');
    try{
      this.setState({hideHistoryDropdown: true});
      if(this.props.phoneNumber == '') return;
      this.props.setPhonePanelVisible(true);
      this.props.setPhoneStatus(this._phoneStatus.CALL_INITIATED);
      this.props.setPhoneClicked(false);
      var date = Date.now()/1000;
      try {
        this._callOperations
        .createCall(this._token, date, this.props.config.extension, '', this.props.config.display_name, this.props.phoneNumber, this.props.displayName)
        .then(callId => {
          console.log('callid');
          console.log(callId);
          this.props.setCallRecordId(callId);
          var callHistory = this.props.callHistory;
          var item = {
            id: 0,
            call_date: date,
            out_ext: this.props.config.extension,
            out_sip_id: "",
            out_display_name: this.props.config.display_name,
            in_number: this.props.phoneNumber,
            in_display_name: this.props.displayName,
            duration: 0,
            billable: 0,
            disposition: ""
          }
          callHistory.unshift(item);
          this.props.setCallHistory(callHistory);

          // var callOptions = this.callOptions;
          // callOptions.extraHeaders = [ 'X-Id:' + callId ]
          // this.setState({isPhonePanelVisible: true, phoneStatus: this._phoneStatus.BUSY});
          // this.phone.call('sip:'+ this.props.phoneNumber + '@' + this.state.config.domain, this.callOptions);
          this.props.phone.call('sip:'+ this.props.phoneNumber, this.callOptions);

          // var oeventHandlers = {
          //   'succeeded': function(data){ console.log(data); },
          //   'failed':    function(data){ console.log(data); }
          // };
          
          // var options = {
          //   'eventHandlers': oeventHandlers,
          //   'extraHeaders': [ 'X-Foo: foo', 'X-Bar: bar' ],
          // };

          // this.props.phone.sendMessage('sip:'+ this.props.phoneNumber, callId, options)
        })
        .catch(error => console.log(error))
      }
      catch(e) { console.log(e); }
    }
    catch(e){
      console.log(e);
    }
  }

  muteCall() {
    try {
      var mute = !this.props.isCallMuted;
      if(mute)
        this.props.callSession.mute();
      else 
        this.props.callSession.unmute();
        
      this.props.setCallOnMute(mute);
    }
    catch(e) {
      console.log(e);
    }
  }

  holdCall() {
    try {
      var hold = !this.props.isCallOnHold;
      if(hold)
        this.props.callSession.hold();
      else 
        this.props.callSession.unhold();
        
      this.props.setCallOnHold(hold);
    }
    catch(e) {
      console.log(e);
    }
  }

  handleCallTransfer(number) {
    if(number == '' || number == null) return;
    this.props.callSession.sendDTMF(`##${number}`);
    this.props.setIsUnattendedCallTransferDialed(true);
  };

  handleCallAttendedTransfer(event, number) {
    switch (event) {
      case 'transfer':
        if(number == '' || number == null) return;
        this.props.callSession.sendDTMF(`*2${number}`);
        this.props.setIsAttendedCallTransferDialed(true);
        break;
      case 'merge':
        this.props.callSession.sendDTMF('*3');
        break;
      case 'swap':
        this.props.callSession.sendDTMF('*4');
        break;
      case 'finish':
        this.props.callSession.sendDTMF('*5');
        break;
      case 'cancel':
        this.props.callSession.sendDTMF('*6');
        break;
      default:
        break;
    }
  };

  render() {
    if(this.props.phoneNumber != '' && !this.props.isPhonePanelVisible) {
      this.props.setPhonePanelVisible(true);
      // this.setState({ isPhonePanelVisible : true });
    }

    if(this.props.isPhoneNumberClicked && this.props.phoneStatus == this._phoneStatus.SERVER_CONNECTED)
      this.dialCall();

    return (
      <React.Fragment>
        <audio muted loop id="audIncomingCall">
          <source src={incomingRingtone} type="audio/mpeg" />
        </audio>
        <audio id="audCall">
        </audio>
        <Dropdown isOpen={this.state.menu} 
          toggle={() => this.setState({menu : !this.state.menu})} 
          style={{display: 'flex', alignItems: 'center', borderRadius: 25, paddingLeft: 10, paddingRight: 5, marginTop:10, height: 50, backgroundColor: (this.props.isPhonePanelVisible && this.props.phoneStatus != this._phoneStatus.SERVER_DISCONNECTED)? 'rgba(255, 255, 255, 0.5)' : 'transparent'}} >
          {
            (this.props.isPhonePanelVisible && this.props.phoneStatus != this._phoneStatus.SERVER_DISCONNECTED) ?
            (
              <div style={{display:'flex', alignItems: 'center'}}>
                <input disabled={this.props.phoneStatus != this._phoneStatus.SERVER_CONNECTED} id="ip_phoneNumber" autoComplete='off' placeholder="Phone Number or SIP Ext..." className="filter text-filter form-control undefined" style={{marginLeft: 10, marginRight: 5,}} value={this.props.phoneNumber} onChange={(e) => this.props.setPhoneNumber(e.target.value, e.target.value)} />

                {
                  this.props.phoneStatus == this._phoneStatus.INCOMING_CALL_RINGING ?
                  (
                    <div style={{display:'flex', alignItems: 'center'}}>
                      <button
                        type="button"
                        title="answer"
                        onClick={this.answerCall}
                        className="btn header-item right-bar-toggle waves-effect"
                      >
                        <i className="mdi mdi-phone" style={{fontSize: 34, color:'green'}}></i>
                      </button>
                      
                      <button
                        type="button"
                        title="disconnect"
                        onClick={this.disconnectCall}
                        className="btn header-item right-bar-toggle waves-effect"
                      >
                        <i className="mdi mdi-phone-hangup" style={{fontSize: 34, color:'red'}}></i>
                      </button>
                    </div>
                  )
                  : null
                }
                
                {
                  this.props.phoneStatus == this._phoneStatus.SERVER_CONNECTED ?
                    <button
                      type="button"
                      title="dial"
                      onClick={this.dialCall}
                      className="btn header-item right-bar-toggle waves-effect"
                      style={{minWidth: 40}}
                    >
                      <i className="mdi mdi-phone" style={{fontSize: 34, color: 'green'}}></i>
                    </button>
                  : null
                }

                {
                  this.props.phoneStatus == this._phoneStatus.BUSY || 
                    this.props.phoneStatus == this._phoneStatus.CALL_INITIATED ?
                  (
                    <div style={{display:'flex', alignItems: 'center'}}>
                      <button
                        type="button"
                        title="disconnect"
                        onClick={this.disconnectCall}
                        className="btn header-item right-bar-toggle waves-effect"
                      >
                        <i className="mdi mdi-phone-hangup" style={{fontSize: 34, color:'red'}}></i>
                      </button>
                    </div>
                  ) : null
                }

                {
                  this.props.phoneStatus == this._phoneStatus.BUSY ?
                  (
                    <div style={{display:'flex', alignItems: 'center'}}>
                      <button
                        type="button"
                        title="mute"
                        onClick={this.muteCall}
                        className="btn header-item right-bar-toggle waves-effect"
                      >
                        {
                          this.props.isCallMuted?
                          <i className="mdi mdi-microphone-off" style={{fontSize: 34, color: 'red'}}></i>
                          : <i className="mdi mdi-microphone" style={{fontSize: 34}}></i>  
                        }
                      </button>

                      <button
                        type="button"
                        title="hold"
                        onClick={this.holdCall}
                        className="btn header-item right-bar-toggle waves-effect"
                      >
                        {
                          this.props.isCallOnHold?
                          <i className="mdi mdi-phone-paused" style={{fontSize: 34, color: 'red'}}></i>
                          : <i className="mdi mdi-phone-paused" style={{fontSize: 34}}></i>  
                        }
                      </button>
                      <Dropdown isOpen={true}
                      toggle={() => {}}  
                      style={{backgroundColor: '#333547'}}
                      >
                        <DropdownMenu right
                          style={{width: 320, maxHeight:50, overflow: 'scroll', 
                                  whiteSpace: 'nowrap', paddingRight: 10, marginTop: 22,  
                                  backgroundColor:'#333547', overflow:'none', borderWidth: 0,}}
                          // id='dvCallHistory' onBlur={(e) => {console.log('call history blur'); this.setState({hideHistoryDropdown: !this.state.hideHistoryDropdown});}} 
                          className={`dropdown-menu-lg p-0`} 
                          // toggle={() => this.setState({hideHistoryDropdown : !this.state.hideHistoryDropdown})}
                          // isOpen={!this.state.hideHistoryDropdown}
                          >
                          {
                            <div style={{display:'flex', alignItems: 'center', backgroundColor: 'rgba(255,255,255,0.5)'}}>
                              {
                                !this.props.isUnattendedCallTransferClicked && !this.props.isAttendedCallTransferClicked?
                                (
                                  <div style={{display:'flex', flexDirection:'row', alignItems: 'center', height: 50}}>
                                    <button
                                      type="button"
                                      title="unattended transfer"
                                      onClick={() => this.props.setIsUnattendedCallTransferClicked(!this.props.isUnattendedCallTransferClicked)}
                                      className="btn header-item right-bar-toggle waves-effect"
                                      style={{maxHeight:50}}
                                    >
                                      <i className="mdi mdi-phone-forward" style={{fontSize: 34}}></i>  
                                    </button>

                                    <button
                                      type="button"
                                      title="attended transfer"
                                      onClick={() => this.props.setIsAttendedCallTransferClicked(!this.props.isAttendedCallTransferClicked)}
                                      className="btn header-item right-bar-toggle waves-effect"
                                      style={{maxHeight:50}}
                                    >
                                      <i className="mdi mdi-phone-return" style={{fontSize: 34}}></i>
                                    </button>
                                  </div>
                                )
                                : null
                              }

                              {
                                this.props.isUnattendedCallTransferClicked || this.props.isAttendedCallTransferClicked?
                                (
                                  <div style={{display:'flex', flexDirection:'row', alignItems: 'center', height: 50}}>
                                    <input disabled={this.props.isAttendedCallTransferDialed || this.props.isUnattendedCallTransferDialed}
                                        id="ip_transferPhoneNumber" autoComplete='off' placeholder="Phone Number or SIP Ext..." 
                                        className="filter text-filter form-control undefined" 
                                        style={{marginLeft: 10, marginRight: 5,}} value={this.props.transferPhoneNumber} 
                                        onChange={(e) => this.props.setTransferPhoneNumber(e.target.value, e.target.value)} 
                                    />

                                    {
                                      !this.props.isAttendedCallTransferDialed?
                                      (
                                        <>
                                          <button
                                            type="button"
                                            title="dial"
                                            onClick={() => {
                                              this.props.isUnattendedCallTransferClicked?
                                                this.handleCallTransfer(this.props.transferPhoneNumber)
                                                : this.props.isAttendedCallTransferDialed? 
                                                    this.handleCallAttendedTransfer('cancel') 
                                                    : this.handleCallAttendedTransfer('transfer', this.props.transferPhoneNumber)
                                            }}
                                            className="btn header-item right-bar-toggle waves-effect"
                                            style={{ alignItems: 'center', display: 'flex' }}
                                          >
                                            <i className="mdi mdi-phone" style={{fontSize: 36, color:'green'}}></i> 
                                          </button>

                                          <button
                                            type="button"
                                            title="close"
                                            // visibility={!this.props.isAttendedCallTransferDialed}
                                            onClick={() => {this.props.setIsAttendedCallTransferClicked(false);this.props.setIsUnattendedCallTransferClicked(false);}}
                                            className="btn header-item right-bar-toggle waves-effect"
                                            style={{maxHeight:50, alignItems: 'center', display: 'flex'}}
                                          >
                                            <i className="mdi mdi-window-close" style={{fontSize: 34}}></i>
                                          </button>
                                          </>
                                      )
                                      : null
                                    }
                                  </div>
                                )
                                : null
                              }

                              {
                                this.props.isAttendedCallTransferDialed?
                                (
                                  <div style={{display:'flex', flexDirection:'row', alignItems: 'center', height: 50}}>
                                    <button
                                      type="button"
                                      title="merge calls"
                                      onClick={() => this.handleCallAttendedTransfer('merge')}
                                      className="btn header-item right-bar-toggle waves-effect"
                                      style={{ alignItems: 'center', display: 'flex' }}
                                    >
                                      <i className="mdi mdi-call-merge" style={{fontSize: 36}}></i> 
                                    </button>

                                    <button
                                      type="button"
                                      title="swap calls"
                                      onClick={() => this.handleCallAttendedTransfer('swap')}
                                      className="btn header-item right-bar-toggle waves-effect"
                                      style={{ alignItems: 'center', display: 'flex' }}
                                    >
                                      <i className="mdi mdi-swap-horizontal" style={{fontSize: 36}}></i> 
                                    </button>

                                    {/* <button
                                      type="button"
                                      onClick={() => this.handleCallAttendedTransfer('finish')}
                                      className="btn header-item right-bar-toggle waves-effect"
                                      style={{ alignItems: 'center', display: 'flex' }}
                                    >
                                      <i className="mdi mdi-phone" style={{fontSize: 36}}></i> 
                                    </button> */}
                                  </div>
                                )
                                : null
                              }
                            </div>
                          }
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )
                  : null
                }

                <div style={{display:'flex', alignItems: 'center'}}>

                  <Dropdown isOpen={!this.state.hideHistoryDropdown}
                    toggle={() => this.setState({hideHistoryDropdown: !this.state.hideHistoryDropdown})}  
                    >
                    <DropdownToggle title="history" className="btn header-item right-bar-toggle waves-effect"
                        style={{ display: 'flex', alignItems: 'center', paddingLeft: 5, backgroundColor: 'transparent'}}
                        onClick={() => {this.getCallHistory(); this.setState({hideHistoryDropdown: !this.state.hideHistoryDropdown}); this.props.setCallMissed(false);}}
                      >
                        <i className="mdi mdi-history" style={{fontSize: 34, color:'white'}}></i>
                        {
                          this.props.isCallMissed?
                            <i className="mdi mdi-record" style={{fontSize: 14, color:'red', marginLeft: -12, marginTop: -18}}></i> 
                          : null
                        }
                    </DropdownToggle>
                    <DropdownMenu right
                      style={{width: 280, maxHeight: 250, overflow: 'scroll', whiteSpace: 'nowrap', paddingRight: 10, marginTop: -10}}
                      // id='dvCallHistory' onBlur={(e) => {console.log('call history blur'); this.setState({hideHistoryDropdown: !this.state.hideHistoryDropdown});}} 
                        className={`dropdown-menu-lg p-0`} 
                        // toggle={() => this.setState({hideHistoryDropdown : !this.state.hideHistoryDropdown})}
                        // isOpen={!this.state.hideHistoryDropdown}
                        >
                      {
                        this.props.callHistory.length > 0 ?
                          this.props.callHistory.map( (item, index) => <PhoneHistoryItem key={index} data={item} ext={this.props.config.extension} />)
                        : <div>No call history found!</div>
                      }
                    </DropdownMenu>
                  </Dropdown>
                    
                </div>
                
                <div style={{display:'flex', alignItems: 'center'}}>

                  <Dropdown isOpen={!this.state.hidePhoneBookDropdown}
                    toggle={() => this.setState({hidePhoneBookDropdown: !this.state.hidePhoneBookDropdown})}  
                    // toggle={() => {}}
                    >
                    <DropdownToggle title="phonebook" className="btn header-item right-bar-toggle waves-effect"
                        style={{ display: 'flex', alignItems: 'center', paddingLeft: 5, backgroundColor: 'transparent'}}
                        onClick={() => {this.setState({hidePhoneBookDropdown: !this.state.hidePhoneBookDropdown});}}
                      >
                        <i className="mdi mdi-contact-phone" style={{fontSize: 34, color:'white'}}></i>
                    </DropdownToggle>
                    <DropdownMenu right
                      style={{width: 450, maxHeight: 600, overflow: 'scroll', whiteSpace: 'nowrap', paddingRight: 10, marginTop: -10}}
                        className={`dropdown-menu-lg p-0`} 
                        >
                        <PhoneBook />
                    </DropdownMenu>
                  </Dropdown>
                    
                </div>
              </div>
            )
            : (<div />)
          }
          
          {
            this.props.phoneStatus == this._phoneStatus.SERVER_DISCONNECTED ?
            (
              <i className="mdi mdi-phone-off" style={{fontSize: 34, color: 'red'}}></i>
            )
            :
            (
              <button
                type="button"
                onClick={this.showPhonePanel}
                className="btn header-item right-bar-toggle waves-effect"
                style={{ alignItems: 'center', display: 'flex' }}
              >
                {
                  this.props.isPhonePanelVisible ?
                    <i className="mdi mdi-window-close" style={{fontSize: 34}}></i>
                  : 
                  <>
                    <i className="mdi mdi-phone" style={{fontSize: 36, color:'green'}}></i>
                    {
                      this.props.isCallMissed?
                        <i className="mdi mdi-record" style={{fontSize: 14, color:'red', marginLeft: -14, marginTop: -20}}></i> 
                      : null
                    }
                  </>
                }
                
              </button>
            )
          }

        </Dropdown>
      </React.Fragment>
    );
  }
}


const mapStatetoProps = state => ({
  phoneNumber: state.Phone.phoneNumber,
  displayName: state.Phone.displayName,
  transferPhoneNumber: state.Phone.transferPhoneNumber,
  transferDisplayName: state.Phone.transferDisplayName,
  phoneStatus: state.Phone.phoneStatus,
  isPhonePanelVisible: state.Phone.isPhonePanelVisible,
  config: state.Phone.config,
  callSession : state.Phone.callSession,
  callOriginator : state.Phone.callOriginator,
  phone: state.Phone.phone,
  isPhoneNumberClicked: state.Phone.isPhoneNumberClicked,
  isCallMuted: state.Phone.isCallMuted,
  isCallOnHold: state.Phone.isCallOnHold,
  isCallMissed: state.Phone.isCallMissed,
  callHistory: state.Phone.callHistory,
  callRecordId: state.Phone.callRecordId,
  isUnattendedCallTransferClicked: state.Phone.isUnattendedCallTransferClicked,
  isUnattendedCallTransferDialed: state.Phone.isUnattendedCallTransferDialed,
  isAttendedCallTransferClicked: state.Phone.isAttendedCallTransferClicked,
  isAttendedCallTransferDialed: state.Phone.isAttendedCallTransferDialed,
})

const mapDispatchtoProps = dispatch => ({
  setPhoneNumber: (number, name) => {
    dispatch({
      type: "SET_PHONE_NUMBER",
      payload: {
          phoneNumber: number,
          displayName: name
      }
    })
  },
  setTransferPhoneNumber: (number, name) => {
    dispatch({
      type: "SET_TRANSFER_PHONE_NUMBER",
      payload: {
          transferPhoneNumber: number,
          transferDisplayName: name
      }
    })
  },
  setPhoneStatus: (status) => {
    dispatch({
      type: "SET_PHONE_STATUS",
      payload: {
          phoneStatus: status
      }
    })
  },
  setPhonePanelVisible: (visibility) => {
    dispatch({
      type: "SET_PHONE_PANEL_VISIBLE",
      payload: {
        isPhonePanelVisible: visibility
      }
    })
  },
  setPhoneConfig: (config) => {
    dispatch({
      type: "SET_PHONE_CONFIG",
      payload: {
          config: config
      }
    })
  },
  setCallSession: (session) => {
    dispatch({
      type: "SET_CALL_SESSION",
      payload: {
          callSession: session
      }
    })
  },
  setCallOriginator: (originator) => {
    dispatch({
      type: "SET_CALL_ORIGINATOR",
      payload: {
        callOriginator: originator
      }
    })
  },
  setPhone: (phone) => {
    dispatch({
      type: "SET_PHONE",
      payload: {
        phone: phone
      }
    })
  },
  setPhoneClicked: (phoneClicked) => {
    dispatch({
      type: "IS_PHONE_NUMBER_CLICKED",
      payload: {
        isPhoneNumberClicked: phoneClicked
      }
    })
  },
  setCallOnMute: (mute) => {
    dispatch({
      type: "IS_CALL_MUTED",
      payload: {
        isCallMuted: mute
      }
    })
  },
  setCallOnHold: (hold) => {
    dispatch({
      type: "IS_CALL_ON_HOLD",
      payload: {
        isCallOnHold: hold
      }
    })
  },
  setCallMissed: (missed) => {
    dispatch({
      type: "IS_CALL_MISSED",
      payload: {
        isCallMissed: missed
      }
    })
  },
  setCallHistory: (history) => {
    dispatch({
      type: "SET_CALL_HISTORY",
      payload: {
        callHistory: history
      }
    })
  },
  setCallRecordId: (id) => {
    dispatch({
      type: "IS_CALL_RECORD_ID",
      payload: {
        callRecordId: id
      }
    })
  },
  setIsUnattendedCallTransferClicked: (isUnattendedCallTransferClicked) => {
    dispatch({
      type: "IS_UNATTENDED_CALL_TRANSFER_CLICKED",
      payload: {
        isUnattendedCallTransferClicked: isUnattendedCallTransferClicked
      }
    })
  },
  setIsUnattendedCallTransferDialed: (isUnattendedCallTransferDialed) => {
    dispatch({
      type: "IS_UNATTENDED_CALL_TRANSFER_DIALED",
      payload: {
        isUnattendedCallTransferDialed: isUnattendedCallTransferDialed
      }
    })
  },
  setIsAttendedCallTransferClicked: (isAttendedCallTransferClicked) => {
    dispatch({
      type: "IS_ATTENDED_CALL_TRANSFER_CLICKED",
      payload: {
        isAttendedCallTransferClicked: isAttendedCallTransferClicked
      }
    })
  },
  setIsAttendedCallTransferDialed: (isAttendedCallTransferDialed) => {
    dispatch({
      type: "IS_ATTENDED_CALL_TRANSFER_DIALED",
      payload: {
        isAttendedCallTransferDialed: isAttendedCallTransferDialed
      }
    })
  },
})

export default connect(mapStatetoProps, mapDispatchtoProps)(PhoneMenu);


