

class CallOperations {
  
  createCall(token, callDate, outExt, outSIPId, outDisplayName, inNumber, inDisplayName) {
    console.log('create call');
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/call/history/create_call/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ token},
        body: JSON.stringify({ callDate: callDate,
          outExt: outExt,
          outSIPId: outSIPId,
          outDisplayName: outDisplayName,
          inNumber: inNumber,
          inDisplayName: inDisplayName })
      })
      .then( 
        res => res.json()
      )
      .then(res => {
        console.log(res);
        resolve(res.id);
      })
      .catch( error => {
        console.log(error);
        reject(error.message);
      })
    });
  }

  answerCall(token, id, answeredDate) {
    fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/call/history/answered/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ token},
      body: JSON.stringify({ id: id, answeredDate: answeredDate })
    })
    .then( 
      res => res.json()
    )
    .catch( error => console.log(error))
  }

  endCall(token, id, endDate) {
    fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/call/history/end/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ token},
      body: JSON.stringify({ id: id, endDate: endDate })
    })
    .then( 
      res => res.json()
    )
    .catch( error => console.log(error))
  }

  updateCallStatus(token, id, status) {
    fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/call/history/update_status/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ token},
      body: JSON.stringify({ id: id, status: status })
    })
    .then( 
      res => res.json()
    )
    .catch( error => console.log(error))
  }

  updateCallStatusFromRemote(token, outExt, inNumber, status) {
    fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/call/history/update_status_from_remote/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Token '+ token},
      body: JSON.stringify({ status: status, outExt: outExt, inNumber: inNumber })
    })
    .then( 
      res => res.json()
    )
    .catch( error => console.log(error))
  }

  // getRecentCalls() {
  //   return new Promise((resolve, reject) => {
  //     fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/call/history/recent/`, {
  //       method: 'GET',
  //       headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + sessionStorage.getItem("authUser").replace(/^"(.*)"$/, '$1') }
  //     })
  //     .then(res => res.json())
  //     .then(data => {
  //       resolve(data);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       reject(error);
  //     });
  //   });
  // }

  getCalls() {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/call/history/getrecords/`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + sessionStorage.getItem("authUser").replace(/^"(.*)"$/, '$1') }
      })
      .then(res => res.json())
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  getPhoneBook(pageNumber, pageSize) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_PORT}/api/users/phonebook/?page=${pageNumber}&page_size=${pageSize}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + sessionStorage.getItem("authUser").replace(/^"(.*)"$/, '$1') }
      })
      .then(res => res.json())
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }
}
export default CallOperations;