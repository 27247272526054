

const initialState = {
  phoneNumber: '',
  displayName: '',
  phoneStatus: 'SERVER_DISCONNECTED',
  isPhonePanelVisible: false,
  config: {
      domain: '',
      uri: '',
      password: '',
      ws_servers: '',
      display_name: "WebRTC Piggy Panel",
      extension: '',
      debug: false,
    },
  callSession : null,
  callOriginator : '',
  phone: null,
  phoneNumberClicked: false,
  isCallOnHold: false,
  isCallMuted: false,
  callOption: 'DISABLED',
  callOptionChanged: false,
  callHistory:[],
  isCallMissed: false,
  callRecordId: null,
  isUnattendedCallTransferClicked: false,
  isAttendedCallTransferClicked: false,
  isUnattendedCallTransferDialed: false,
  isAttendedCallTransferDialed: false,
  transferPhoneNumber: '',
  transferDisplayName: '',
}

const Phone = (state = initialState, action) => {
  switch (action.type) {
      case "SET_PHONE_NUMBER":
          state = {
              ...state,
              phoneNumber: action.payload.phoneNumber,
              displayName: action.payload.displayName
          }
          break;      
      case "SET_PHONE_STATUS":
          state = {
              ...state,
              phoneStatus: action.payload.phoneStatus
          }
          break;      
      case "SET_PHONE_PANEL_VISIBLE":
        state = {
            ...state,
            isPhonePanelVisible: action.payload.isPhonePanelVisible
        }
        break;      
      case "SET_PHONE_CONFIG":
        state = {
            ...state,
            config: action.payload.config
        }
        break;      
      case "SET_CALL_SESSION":
        state = {
            ...state,
            callSession: action.payload.callSession
        }
        break;      
      case "SET_CALL_ORIGINATOR":
        state = {
            ...state,
            callOriginator: action.payload.callOriginator
        }
        break;      
      case "SET_PHONE":
        state = {
          ...state,
          phone: action.payload.phone
        }
        break;
      case "IS_PHONE_NUMBER_CLICKED":
        state = {
          ...state,
          isPhoneNumberClicked: action.payload.isPhoneNumberClicked
        }
        break;
      case "IS_CALL_MUTED":
        state = {
          ...state,
          isCallMuted: action.payload.isCallMuted
        }
        break;
      case "IS_CALL_ON_HOLD":
        state = {
          ...state,
          isCallOnHold: action.payload.isCallOnHold
        }
        break;
      case "SET_CALL_OPTION":
        state = {
          ...state,
          callOption: action.payload.callOption
        }
        break;
      case "SET_CALL_OPTION_CHANGED":
        state = {
          ...state,
          callOptionChanged: action.payload.callOptionChanged
        }
        break;
      case "SET_CALL_HISTORY":
        state = {
          ...state,
          callHistory: action.payload.callHistory
        }
        break;
      case "IS_CALL_MISSED":
        state = {
          ...state,
          isCallMissed: action.payload.isCallMissed
        }
        break;
      case "IS_CALL_RECORD_ID":
        state = {
          ...state,
          callRecordId: action.payload.callRecordId
        }
        break;
      case "IS_UNATTENDED_CALL_TRANSFER_CLICKED":
        state = {
          ...state,
          isUnattendedCallTransferClicked: action.payload.isUnattendedCallTransferClicked
        }
        break;
      case "IS_UNATTENDED_CALL_TRANSFER_DIALED":
        state = {
          ...state,
          isUnattendedCallTransferDialed: action.payload.isUnattendedCallTransferDialed
        }
        break;
      case "IS_ATTENDED_CALL_TRANSFER_CLICKED":
        state = {
          ...state,
          isAttendedCallTransferClicked: action.payload.isAttendedCallTransferClicked
        }
        break;
      case "IS_ATTENDED_CALL_TRANSFER_DIALED":
        state = {
          ...state,
          isAttendedCallTransferDialed: action.payload.isAttendedCallTransferDialed
        }
        break;
      case "SET_TRANSFER_PHONE_NUMBER":
        state = {
            ...state,
            transferPhoneNumber: action.payload.transferPhoneNumber,
            transferDisplayName: action.payload.transferDisplayName
        }
        break;
      default:
        state = { ...state };
        break;
  }
  return state;
}

export default Phone;